import { Avatar, Box, Container, Grid, Stack, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import TextPreview from '../components/TextPreview';
import Banner from '../components/banner/Banner';
import ImagesPreview from '../components/ImagesPreview';
import PieChartPreview from '../components/PieChartPreview';
import BarAndLineGraphPreview from '../components/BarAndLineGraphPreview';
import VideoPreview from '../components/VideoPreview';
import VideoFilePreview from '../components/VideoFilePreview';
import EmbedPreview from '../components/EmbedPreview';

export default function PreviewPage({ feed, contents }) {
  /// function to make first letter of the words in a string uppercase
  const capitalizeFirstLetter = (string) => string
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

  return (
    <Container
      maxWidth="detailContainer"
      sx={{
        backgroundColor: '#FFF',
        paddingY: '20px',
      }}
      style={{
        paddingBottom: '100px',
      }}
    >
      <Box sx={{ maxWidth: '900px', margin: '0 auto' }} padding={1}>
        <Grid container spacing={4} sx={{ maxWidth: '900px' }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography
              sx={{
                color: '#BE0000',
                fontFamily: 'Avenir Heavy',
                fontWeight: 500,
                fontSize: '24px',
                lineHeight: '36px',
              }}
            >
              {feed.title}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography
              sx={{
                fontFamily: 'Archivo, sans-serif ',
                fontSize: '16px',
                fontWeight: 400,
                color: '#A9A9A9',
                letterSpacing: '0.03em',
                lineHeight: '24px',
              }}
            >
              {feed.summary}
            </Typography>
          </Grid>
          {feed?.authorName && (
            <Grid item xs={12}>
              <Stack direction="row" spacing={1} alignItems="center">
                {feed?.authorProfilePic && <Avatar alt="Remy Sharp" src={feed?.authorProfilePic} />}
                <Stack direction="column" spacing={0.5}>
                  <Typography
                    sx={{
                      fontFamily: 'Roboto',
                      fontSize: '14px',
                      fontWeight: 600,
                      lineHeight: '12px',
                    }}
                  >
                    By {feed?.authorName && capitalizeFirstLetter(feed?.authorName)}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Roboto',
                      fontSize: '12px',
                      fontWeight: 400,
                      lineHeight: '12px',
                      color: 'grey.700',
                    }}
                  >
                    {moment(feed?.createdAt).format('MMMM DD, YYYY')}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{  margin: '0 auto' }}
            >
              <Banner image={feed.images || []} />
              <Typography
                variant="body1"
                sx={{
                  fontFamily: ' Archivo, sans-serif !important',
                  fontSize: '14px',
                  textAlign: 'left',
                  width: '100%',
                  color: 'grey.700',
                  marginTop: '5px',
                }}
              >
                <blockquote>
                  {feed.imageDescription &&
                    feed.imageDescription?.charAt(0).toUpperCase() + feed.imageDescription?.slice(1)}
                </blockquote>
              </Typography>
            </Stack>
          </Grid>

          {contents.map((item, key) => (
            <Grid
              key={key}
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              marginY={item.contentType === 'PIE_CHART' || item.contentType === 'BAR_GRAPH' ? 2 : 0}
            >
              {item.contentType === 'TEXT' && <TextPreview item={item} />}
              {item.contentType === 'IMAGE' && <ImagesPreview item={item} />}
              {item.contentType === 'PIE_CHART' && <PieChartPreview item={item} />}
              {item.contentType === 'BAR_GRAPH' && <BarAndLineGraphPreview item={item} />}
              {item.contentType === 'VIDEO_LINK' && <VideoPreview item={item} />}
              {item.contentType === 'VIDEO' && <VideoFilePreview item={item} />}
              {item.contentType === 'EMBED_CODE' && <EmbedPreview item={item} />}
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
}
